import "./reserve.css";

export default function Reserve({ isVisible, hideReserve }) {
    return(
        <>
            <div id="reserve_main"
                    style={{
                    visibility: isVisible ? "visible" : "hidden",
                    opacity: isVisible ? 1 : 0,
                    // transition: "opacity 0.5s ease, visibility 0.5s ease", 
                }}
            >
                <section>
                    <img className="reserve_desk" src="/images/meniu/reserve.avif" alt="reserve" loading="lazy"/>
                    <img className="reserve_mobile" src="/images/meniu/reserve_mobile.avif" alt="reserve" loading="lazy"/>
                    <div className="meniu_exit" onClick={hideReserve}>
                        <img src="/images/meniu/meniu_exit.avif" alt="meniu_exit" />
                    </div>
                    <section className="res_text_area">
                        <h2>Ieškai išskirtinio sprendimo <br /> savo <br /> šventei?</h2>
                        <h3>Susisiek su mumis ir <br /> kartu sukursime kažką ypatingo.</h3>
                        <p>Maisto ir gėrimų meniu gali būti <br /> derinami individualiai – siūlome vegetariškas, veganiškas opcijas, taip pat nealkoholinius gėrimus ir kitus <br /> pasirinkimus pagal užsakymą.</p>
                    </section>
                    <div className="res_buttons">
                        <a href="tel:+37067346095" target="_blank">
                            <img src="/images/buttons/reserve_phone_button.webp" alt="phone" />
                        </a>
                        <a href="mailto:labas@niekotokio.catering" target="_blank">
                            <img src="/images/buttons/reserve_email_button.webp" alt="email" />
                        </a>
                    </div>
                </section>
            </div>
        </>
    );
}