import "./meniu.css";

export default function Meniu({ isVisible, hideMeniu }) {

    return(
        <>
            <div
                id="meniu_main"
                style={{
                    visibility: isVisible ? "visible" : "hidden",
                    opacity: isVisible ? 1 : 0,
                    // transition: "opacity 0.5s ease, visibility 0.5s ease", 
                }}
            >
                <div className="meniu_maistas">
                <div className="meniu_exit" onClick={hideMeniu}>
                    <section>
                        <img src="/images/meniu/meniu_exit.avif" alt="meniu_exit" />
                    </section>
                </div>
                    <section>
                        <img className="desk_m_menu" src="/images/meniu/maisto.avif" alt="meniu1" loading="lazy"/>
                        <img className="mobile_m_menu" src="/images/meniu/maisto_mobile.avif" alt="meniu1" loading="lazy"/>
                        <div className="mm_list">
                            <section>
                                <h2>MAISTAS</h2>
                                <div>
                                    <h4>Užkandis</h4>
                                    <h5>Krevetės ir avokado sevičė</h5>
                                </div>
                                <div>
                                    <h4>Sriuba</h4>
                                    <h5>Plakto kiaušinio sriuba</h5>
                                </div>
                                <div>
                                    <h4>Sashimi Moriawase</h4>
                                    <h5>Skumbrė, lašiša, sviestžuvė, <br/> jautienos tattaki</h5>
                                </div>
                                <div>
                                    <h4>Futomaki</h4>
                                    <h5>Didelis suktinukas su sezoninėmis <br/> šviežiomis, marinuotomis daržovėmis</h5>
                                </div>
                                <div>
                                    <h4>Nigiri</h4>
                                    <h5>Skumbrė, lašiša, sviestžuvė, <br/> jautienos tattaki</h5>
                                </div>
                                <div>
                                    <h4>Kaburi maki salmon</h4>
                                    <h5>Lašiša, krevetė, avokadas, <br/> teriyaki padažas</h5>
                                </div>
                                <div>
                                    <h4>Kaburi maki beef</h4>
                                    <h5>Jautienos tattaki, sviestžuvė, <br/> chimichurri padažas</h5>
                                </div>
                                <div>
                                    <h4>Miso sriuba</h4>
                                </div>
                                <div>
                                    <h4>Hoso maki</h4>
                                    <h5>Suktinukas su marinuota skumbre</h5>
                                </div>
                            </section>
                        </div>
                    </section>
                </div>
                <div className="meniu_gerimai">
                    <section>
                        <img className="desk_g_menu" src="/images/meniu/gerimu.avif" alt="gerimu" loading="lazy"/>
                        <img className="mobile_g_menu" src="/images/meniu/gerimu_mobile.avif" alt="meniu1" loading="lazy"/>
                        <div className="mm_list">
                            <section>
                                <h2>Gėrimai</h2>
                                <div>
                                    <h4>Arbatos ceremonija </h4>
                                    <h5>Ulong, žalia, juoda arbata</h5>
                                </div>
                                <div>
                                    <h4>Sakė / putojantis vynas</h4>
                                    <h5>Ryžių vynas</h5>
                                </div>
                                <div>
                                    <h4>Japaricano</h4>
                                    <h5>Bitteris, yuzu, tonikas</h5>
                                </div>
                                <div>
                                    <h4>Butterfly sake sour</h4>
                                    <h5>Balnapupės, sakė, žalioji citrina</h5>
                                </div>
                                <div>
                                    <h4>Sixth sense</h4>
                                    <h5>Umami viskio soueris</h5>
                                </div>
                                <div>
                                    <h4>Sezoninis likeris</h4>
                                    <h5>Unikalus azijietiškas likeris</h5>
                                </div>
                            </section>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}