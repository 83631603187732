import React, { useState, useEffect } from "react";
import "./home.css";
import "./home.animations.css";
import Meniu from "../meniu/meniu";
import Reserve from "../reserve/reserve";

export default function Home() {
    const [isMenuVisible, setMenuVisible] = useState(false);
    const [isReserveVisible, setReserveVisible] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isHeroLoaded, setHeroLoaded] = useState(false);

    // Function to determine if mobile view or desktop view
    const isMobileView = () => window.innerWidth <= 768;

    // Set image array based on screen size
    const getImageArray = () => {
        if (isMobileView()) {
            return [
                "/images/buttons/meniu_button.svg",
                "/images/buttons/reserve_button.svg",
                "/images/shrimp_mobile.webp",
                "/images/koi_mobile.webp",
            ];
        } else {
            return [
                "/images/buttons/meniu_button.svg",
                "/images/buttons/reserve_button.svg",
                "/images/shrimp.avif",
                "/images/koi.avif",
            ];
        }
    };

    // Preload images based on initial screen size
    useEffect(() => {
        const images = getImageArray();

        // Preload hero image separately
        const heroImage = new Image();
        heroImage.src = "/images/Hero_bg.avif";
        heroImage.onload = () => setHeroLoaded(true);
        heroImage.onerror = () => setHeroLoaded(true);

        // Preload remaining images after hero image is loaded
        if (isHeroLoaded) {
            let loadedImagesCount = 0;
            const handleImageLoad = () => {
                loadedImagesCount++;
                if (loadedImagesCount === images.length) {
                    // Set a minimum 1s delay for the preloader
                    setTimeout(() => setLoading(false), 1000);
                }
            };

            images.forEach((src) => {
                const img = new Image();
                img.src = src;
                img.onload = handleImageLoad;
                img.onerror = handleImageLoad;
            });
        }
    }, [isHeroLoaded]); // Run this effect when the hero image is loaded

    const showMeniu = () => {
        setMenuVisible(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const hideMeniu = () => {
        setMenuVisible(false);
    };
    const showReserve = () => {
        setReserveVisible(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const hideReserve = () => {
        setReserveVisible(false);
    };

    // Disable scrolling when the menu is visible
    useEffect(() => {
        if (isMenuVisible) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }, [isMenuVisible]);

    if (isLoading) {
        return (
            <div className="loading_screen">
                <a href="#">
                    <img src="/images/logo.svg" alt="logo" />
                </a>
            </div>
        );
    }

    return (
        <div className="home_hero">
            <Meniu isVisible={isMenuVisible} hideMeniu={hideMeniu} />
            <Reserve isVisible={isReserveVisible} hideReserve={hideReserve} />
            <div
                className="hh_bg"
                style={{
                    backgroundImage: `url('/images/Hero_bg.avif')`
                }}
            >
                <div className="hh_content">
                    <div className="hh_social">
                        <a href="https://www.facebook.com/profile.php?id=61566595971602" target="_blank" rel="noreferrer">FACEBOOK</a>
                        <a href="https://www.instagram.com/niekotokio.catering" target="_blank" rel="noreferrer">INSTAGRAM</a>
                    </div>
                    <div className="hh_title">
                        <h2>Jau netrukus pasimatysime!</h2>
                        <h1>Japonijos įkvėptoje edukacinėje gastro kelionėje, jūsų namuose</h1>
                    </div>
                    <div className="hh_mail">
                        <a href="mailto:labas@niekotokio.catering">labas@niekotokio.catering</a>
                    </div>
                </div>
                <div className="hh_bottom">
                    <div className="hh_meniu" onClick={showMeniu}>
                        <img src="/images/buttons/meniu_button.svg" alt="meniu" />
                    </div>
                    <div className="hh_reserve" onClick={showReserve}>
                        <img src="/images/buttons/reserve_button.svg" alt="reserve" />
                    </div>
                </div>
                
                <div className="hh_shrimp">
                    <picture>
                        <source media="(max-width: 768px)" srcSet="/images/shrimp_mobile.webp" type="image/webp" />
                        <img className="desk_shrimp" src="/images/shrimp.avif" alt="shrimp" draggable="false" />
                    </picture>
                </div>
                <div className="hh_koi">
                    <picture>
                        <source media="(max-width: 768px)" srcSet="/images/koi_mobile.webp" type="image/webp" />
                        <img className="desk_koi" src="/images/koi.avif" alt="koi" draggable="false" />
                    </picture>
                </div>

            </div>
            <div className="hh_mobile_content">
                <div className="hh_social">
                    <a href="https://www.facebook.com/profile.php?id=61566595971602">FACEBOOK</a>
                    <a href="https://www.instagram.com/niekotokio.catering">INSTAGRAM</a>
                </div>
                <div className="hh_mail">
                    <a href="mailto:labas@niekotokio.catering">labas@niekotokio.catering</a>
                </div>
            </div>
        </div>
    );
}
